import axios from "axios";

import {
  LOADING_PRODUCTS,
  SET_PRODUCTS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_QUANTITY,
  UPDATE_PRODUCT_DISCOUNT,
  UPDATE_ORDER_DISCOUNT,
} from "../types/product";

export const getProducts = async (dispatch, search) => {
  if (search === "") {
    dispatch({
      type: SET_PRODUCTS,
      payload: [],
    });
    return;
  }

  dispatch({
    type: LOADING_PRODUCTS,
  });

  try {
    const products = await axios.get(`products?search=${search}`);

    dispatch({
      type: SET_PRODUCTS,
      payload: products.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addProductToCart = (dispatch, product, cartProducts) => {
  const checkIndex = cartProducts.findIndex((item) => item.id === product.id);

  if (checkIndex !== -1) {
    const newQuantity = parseInt(cartProducts[checkIndex].quantity) + 1;

    dispatch({
      type: UPDATE_CART_QUANTITY,
      payload: {
        quantity: newQuantity.toString(),
        product,
        cartProducts,
      },
    });
  } else {
    dispatch({
      type: ADD_TO_CART,
      payload: { quantity: "1", discount: "0", ...product },
    });
  }
};

export const removeFromCart = (dispatch, product) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: product,
  });
};

export const updateCartQuantity = (
  dispatch,
  cartProducts,
  product,
  quantity
) => {
  dispatch({
    type: UPDATE_CART_QUANTITY,
    payload: { quantity, product, cartProducts },
  });
};

export const updateProductDiscount = (
  dispatch,
  cartProducts,
  product,
  discount
) => {
  dispatch({
    type: UPDATE_PRODUCT_DISCOUNT,
    payload: { discount, product, cartProducts },
  });
};

export const updateOrderDiscount = (dispatch, orderDiscount) => {
  dispatch({
    type: UPDATE_ORDER_DISCOUNT,
    payload: orderDiscount,
  });
};
