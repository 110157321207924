import React, { useEffect } from "react";

import { TableBody, TableCell, TableRow } from "@material-ui/core";

// @material-ui/lab components
import { Skeleton } from "@material-ui/lab";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../redux/actions/customerActions";

// helpers
import { formatCurrency } from "../../utils/helpers";

const CustomersTableData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getCustomers(dispatch);
  }, [dispatch]);

  const {
    customers: { loading, data },
  } = useSelector((state) => state.customer);

  return loading ? (
    <TableRow
      hover
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      key={1}
      selected={false}
    >
      <TableCell>
        <Skeleton />
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
        <Skeleton />
      </TableCell>
    </TableRow>
  ) : (
    <TableBody>
      {data.map(
        ({
          name,
          phone_number,
          total_sales,
          complete_orders,
          pendingPayments,
        }) => {
          return (
            <TableRow
              hover
              aria-checked={false}
              tabIndex={-1}
              key={1}
              selected={false}
            >
              <TableCell align="left">{name}</TableCell>
              <TableCell align="left">{phone_number}</TableCell>
              <TableCell align="right">
                {formatCurrency("KES", complete_orders)}
              </TableCell>
              <TableCell>{formatCurrency("KES", pendingPayments)}</TableCell>
              <TableCell align="left">
                {formatCurrency("KES", total_sales)}
              </TableCell>
            </TableRow>
          );
        }
      )}
    </TableBody>
  );
};

export default CustomersTableData;
