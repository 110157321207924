import axios from "axios";

import {
  SEARCHING_CUSTOMER_DETAILS,
  SET_CUSTOMER_DETAILS,
  LOADING_CUSTOMERS,
  SET_CUSTOMERS,
} from "../../redux/types/customer";

export const getCustomerByPhoneNumber = async (dispatch, phoneNumber) => {
  dispatch({
    type: SEARCHING_CUSTOMER_DETAILS,
    payload: phoneNumber,
  });

  try {
    const customer = await axios.get(`customers/${phoneNumber}/details`);

    if (customer.data.data.phone_number === "") {
      customer.data.data.phone_number = phoneNumber;
    }

    dispatch({
      type: SET_CUSTOMER_DETAILS,
      payload: customer.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCustomers = async (dispatch) => {
  dispatch({
    type: LOADING_CUSTOMERS,
  });

  try {
    const response = await axios.get("customers");

    setTimeout(() => {
      dispatch({
        type: SET_CUSTOMERS,
        payload: response.data.data,
      });
    }, 5000);
  } catch (error) {
    console.log(error);
  }
};
