import React, { useEffect } from "react";
import { getShippingAreas } from "../../redux/actions/shipping";

// @material-ui/skeleton components
import Skeleton from "@material-ui/lab/Skeleton";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

// Redux
import {
  UPDATE_DELIVERY_ADDRESS,
  SET_SHIPPING_AREA,
} from "../../redux/types/product";
import { useDispatch, useSelector } from "react-redux";

const DeliveryDetails = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getShippingAreas(dispatch);
  }, [dispatch]);

  const { loading, shippingAreas } = useSelector((state) => state.shipping);

  const { deliveryAddress, shippingArea } = useSelector(
    (state) => state.product
  );

  return loading ? (
    <>
      <Skeleton animation="wave" />
      <Skeleton />
      <Skeleton animation="wave" />
    </>
  ) : (
    <FormControl variant="outlined" m={2} style={{ width: "100%" }}>
      <InputLabel id="demo-simple-select-outlined-label">
        Shipping Area
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label="Shipping Area"
        value={shippingArea}
        onChange={(event) => {
          dispatch({
            type: SET_SHIPPING_AREA,
            payload: event.target.value,
          });
        }}
      >
        {shippingAreas.map((area) => (
          <MenuItem key={area.id} value={area.id}>
            {area.name}
          </MenuItem>
        ))}
      </Select>

      <TextField
        placeholder="Delivery Address"
        multiline
        rows={3}
        rowsMax={4}
        value={deliveryAddress}
        variant="outlined"
        style={{ marginTop: "20px" }}
        onChange={(event) => {
          dispatch({
            type: UPDATE_DELIVERY_ADDRESS,
            payload: event.target.value,
          });
        }}
      />
    </FormControl>
  );
};

export default DeliveryDetails;
