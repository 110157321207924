import React, { useState } from "react";
import styled from "styled-components/macro";

// @material-ui/core components
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons components
import CloseIcon from "@material-ui/icons/Close";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { submitOrder } from "../../redux/actions/orderActions";

const CompleteOrderBtn = styled(Button)`
  margin-top: 10px;
  height: 50px;
  font-size: 20px;
`;

const CompleteOrderButton = ({ orderTotal }) => {
  const dispatch = useDispatch();
  const { customerDetails } = useSelector((state) => state.customer);
  const {
    orderDiscount,
    shippingArea,
    deliveryAddress,
    cartProducts,
    specialNotes,
  } = useSelector((state) => state.product);

  const { ApiResponse, sendSMS, loading } = useSelector((state) => state.order);

  const [state, setState] = useState({
    open: true,
    vertical: "top",
    horizontal: "right",
  });

  const TransitionRight = (props) => {
    return <Slide {...props} direction="right" />;
  };

  const [transition] = useState(() => TransitionRight);

  const { vertical, horizontal, open } = state;

  const orderPayload = {
    products: cartProducts,
    customer: customerDetails,
    orderDiscount,
    shippingArea,
    deliveryAddress,
    specialNotes,
    sendSMS,
  };

  const closeOrderSuccessSnackbar = () => {
    setState({
      ...state,
      open: false,
    });
  };

  return (
    <>
      {ApiResponse.status === 200 ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          TransitionComponent={transition}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={`Order submitted successfully.`}
          key={vertical + horizontal}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeOrderSuccessSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      ) : (
        ""
      )}

      <CompleteOrderBtn
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={() => submitOrder(dispatch, orderPayload)}
        disabled={
          cartProducts.length === 0 ||
          shippingArea === "" ||
          deliveryAddress === "" ||
          customerDetails.name === "" ||
          customerDetails.phone_number === "" ||
          orderDiscount >= orderTotal ||
          loading
        }
      >
        Complete Order
      </CompleteOrderBtn>
    </>
  );
};

export default CompleteOrderButton;
