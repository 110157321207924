import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CardContent, TextField } from "@material-ui/core";
import styled from "styled-components/macro";

// Redux
import { getCustomerByPhoneNumber } from "../../redux/actions/customerActions";
import {
  SEARCHING_CUSTOMER_DETAILS,
  SET_CUSTOMER_NAME,
} from "../../redux/types/customer";

const TextFieldWrapper = styled(TextField)`
  margin-bottom: 20px;
`;

const CustomerDetails = () => {
  const dispatch = useDispatch();

  const { phoneNumber, customerDetails } = useSelector(
    (state) => state.customer
  );

  useEffect(() => {
    getCustomerByPhoneNumber(dispatch, phoneNumber);
  }, [dispatch, phoneNumber]);

  return (
    <CardContent>
      <TextFieldWrapper
        type="text"
        name="phone"
        label="Customer Phone"
        fullWidth
        my={2}
        variant="outlined"
        autoComplete="off"
        value={customerDetails.phone_number}
        onChange={(event) =>
          dispatch({
            type: SEARCHING_CUSTOMER_DETAILS,
            payload: event.target.value,
          })
        }
      />

      <TextField
        type="text"
        name="customerName"
        label="Customer Name"
        fullWidth
        my={2}
        variant="outlined"
        autoComplete="off"
        value={customerDetails.name}
        disabled={customerDetails.id !== 0}
        onChange={(event) =>
          dispatch({
            type: SET_CUSTOMER_NAME,
            payload: event.target.value,
          })
        }
      />
    </CardContent>
  );
};

export default CustomerDetails;
